<template>
  <v-dialog v-model="OrderArticle" width="475px" overlay-opacity="0.75" scrollable>
    <v-card tile class="pa-3">
      <v-card-title
        ><v-col cols="12" class="pa-0">
          <v-row dense class="align-center">
            <v-col cols="auto">
              <v-toolbar-title>Artikelbestellung vormerken</v-toolbar-title>
            </v-col>
          </v-row>
        </v-col>
      </v-card-title>
      <v-card-text class="text--primary py-5 px-3 subtitle-2">
        <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            <v-col cols="12">
              Bitte benötigte Menge eintragen:
            </v-col>
          </v-row>
          <v-row dense align="center" class="subtitle-2">
            <v-col cols="12">
              <span class="font-weight-bold">zu bestellende Menge</span>
              <v-text-field dense hide-details outlined clearable placeholder="0" v-model="Bestellmenge"> </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn outlined color="red lighten-1" @click="abortOrderDataset()">
                <span>Abbrechen</span>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="green lighten-1" @click="confirmOrderDataset()" :disabled="!Bestellmenge">
                <v-icon left class="grey--text text--lighten-4">mdi-check </v-icon>
                <span class="grey--text text--lighten-4">Vormerken</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dataset: { type: Object, default: Object },
  },

  data: () => ({
    OrderArticle: false,
    orderedDataset: {},
    Bestellmenge: null,
  }),

  methods: {
    open() {
      this.OrderArticle = true;
    },

    confirmOrderDataset() {
      this.OrderArticle = false;
      this.$emit("confirmOrder", this.Bestellmenge);
    },

    abortOrderDataset() {
      this.OrderArticle = false;
      this.$emit("abortOrder", true);
    },
  },
};
</script>
