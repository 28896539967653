<template>
  <v-layout width="100%">
    <v-app-bar app height="124" class="align-start elevation-1">
      <v-col cols="12" class="py-0">
        <v-row dense align="center">
          <v-col cols="auto" class="d-flex d-lg-none pa-0">
            <!--Platzhalter für mobile Menu-->
            <div style="width: 25px"></div>
          </v-col>
          <v-col cols="auto">
            <v-btn icon x-large @click="$router.go(-1)">
              <v-icon x-large>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-toolbar-title>
              {{ dataset.Artikelname }}
            </v-toolbar-title>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              @click="openOrderArticle()"
              color="orange lighten-2"
              :disabled="!dataset.Hersteller_ID || (dataset.Bestellstatus_ID !== 2 && dataset.Bestellstatus_ID !== 4)"
            >
              <v-icon class="grey--text text--lighten-4">mdi-cart-outline</v-icon>
              <span class="ml-1 grey--text text--lighten-4 d-none d-lg-flex">
                Bestellen
              </span>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn color="orange lighten-2" :to="linkEditDataset">
              <v-icon class="grey--text text--lighten-4">mdi-pencil</v-icon>
              <span class="ml-1 grey--text text--lighten-4 d-none d-lg-flex">
                Bearbeiten
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-app-bar>
    <v-container fluid class="px-0">
      <v-col cols="11" xl="8" lg="9" md="11" class="mx-auto px-0">
        <!--Hauptdaten-->
        <v-row dense class="align-center justify-center pb-5">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-title class="px-2">
                <v-row dense>
                  <v-col cols="12">
                    <v-toolbar-title>
                      Hauptdaten
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider inset></v-divider>
              <v-card-text class="px-2 subtitle-2">
                <v-row dense align="start">
                  <v-col cols="12">
                    <v-row dense>
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        Artikelname
                      </v-col>
                      <v-col cols="6">
                        <span v-if="dataset.Artikelname">
                          {{ dataset.Artikelname }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        Kategorie
                      </v-col>
                      <v-col cols="6" class="">
                        <span>
                          {{ dataset.Abteilung }}
                        </span>
                        <span v-if="dataset.Artikelgruppe">
                          {{ " / " + dataset.Artikelgruppe }}
                        </span>
                        <span v-if="dataset.Artikeluntergruppe">
                          {{ " / " + dataset.Artikeluntergruppe }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        Art.-Nr.
                      </v-col>
                      <v-col cols="6">
                        <span v-if="dataset.Artikelnummer">
                          {{ dataset.Artikelnummer }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        Größe
                      </v-col>
                      <v-col cols="6">
                        <span v-if="dataset.Größe">
                          {{ dataset.Größe }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider inset class="my-3"></v-divider>
                <v-row dense align="start">
                  <v-col cols="12" xl="6" lg="6" md="6">
                    <v-row dense>
                      <v-col cols="12" class="text-no-wrap">
                        <v-row dense>
                          <v-col cols="5" md="6" sm="6" class="font-weight-bold">
                            Status
                          </v-col>
                          <v-col cols="6">
                            <span v-if="dataset.Status">
                              {{ dataset.Status }}
                            </span>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="5" md="6" sm="6" class="font-weight-bold">
                            Bestellstatus
                          </v-col>
                          <v-col cols="6">
                            <span v-if="(dataset.Bestellstatus_ID === 1 && dataset.Hersteller_ID) || dataset.Bestellstatus_ID > 1">
                              {{ dataset.Bestellstatus }}
                            </span>
                            <span v-else-if="dataset.Bestellstatus_ID === 1 && !dataset.Hersteller_ID">
                              Hersteller fehlt
                            </span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" xl="6" lg="6" md="6">
                    <v-row dense>
                      <v-col cols="12" class="text-no-wrap">
                        <v-row dense>
                          <v-col cols="5" md="6" sm="6" class="font-weight-bold">
                            Bestand
                          </v-col>
                          <v-col cols="6">
                            <span>
                              {{ dataset.Bestand }}
                            </span>
                            <span>
                              {{ dataset.Einheit }}
                            </span>
                            <span v-if="datasetPreorders.zu_bestellende_Menge">
                              (+ {{ datasetPreorders.zu_bestellende_Menge }} {{ dataset.Einheit }} )
                            </span>
                          </v-col>
                        </v-row>
                        <v-row dense v-if="dataset.ist_bestandsgeführt">
                          <v-col cols="5" md="6" sm="6" class="font-weight-bold">
                            Mindestbestand
                          </v-col>
                          <v-col cols="6">
                            <span v-if="dataset.Mindestbestand"> {{ dataset.Mindestbestand }} {{ dataset.Einheit }} </span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-card>
        </v-row>
        <!--Artikeleigenschaften und Beschreibung-->
        <v-row dense class="align-center justify-center pb-5" v-if="dataset.Status_ID !== 6">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-title class="px-2">
                <v-row dense>
                  <v-col cols="12">
                    <v-toolbar-title>
                      Eigenschaften / Details
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider inset></v-divider>
              <v-card-text class="px-2 subtitle-2">
                <v-row dense align="start">
                  <v-col cols="12">
                    <v-row dense>
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        Farbe(n)
                      </v-col>
                      <v-col cols="7" xl="9" lg="9" md="9" sm="6">
                        <span v-if="dataset.Hauptfarbe">
                          {{ dataset.Hauptfarbe }}
                        </span>
                        <span v-if="dataset.Nebenfarbe">
                          {{ " & " + dataset.Nebenfarbe }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        Stoff(e)
                      </v-col>
                      <v-col cols="7" xl="9" lg="9" md="9" sm="6">
                        <span v-if="dataset.Hauptstoff">
                          {{ dataset.Hauptstoff }}
                        </span>
                        <span v-if="dataset.Nebenstoff">
                          {{ " & " + dataset.Nebenstoff }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        Schnitt
                      </v-col>
                      <v-col cols="7" xl="9" lg="9" md="9" sm="6">
                        <span v-if="dataset.Schnitt">
                          {{ dataset.Schnitt }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        Besonderheiten
                      </v-col>
                      <v-col cols="7" sm="6">
                        <span v-if="dataset.Besonderheiten">
                          {{ dataset.Besonderheiten }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider inset class="my-3"></v-divider>
                <v-row dense align="start">
                  <v-col cols="12">
                    <v-row dense align="center">
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        Hersteller
                      </v-col>
                      <v-col cols="5" v-if="dataset.Hersteller">
                        {{ dataset.Hersteller }}
                      </v-col>
                      <v-col cols="1" v-if="dataset.Hersteller">
                        <v-btn icon outlined :to="linkShowManufacturer"><v-icon>mdi-factory</v-icon></v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-card>
        </v-row>
        <!--Artikelpreise -->
        <v-row dense class="align-center justify-center pb-5">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-title class="px-2">
                <v-row dense>
                  <v-col cols="12">
                    <v-toolbar-title>
                      Preise
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider inset></v-divider>
              <v-card-text class="px-2 subtitle-2">
                <v-row dense align="start">
                  <v-col cols="12" xl="6" lg="6" md="6">
                    <v-row dense>
                      <v-col cols="5" md="6" sm="6" class="font-weight-bold"> EK netto <span v-if="checkCurrentUser">(normal)</span> </v-col>
                      <v-col cols="6">
                        <span v-if="dataset.EK_netto">{{
                          String(parseFloat(dataset.EK_netto.replace(",", ".").replace(" ", "")).toFixed(2)).replace(".", ",") + " €"
                        }}</span>
                      </v-col>
                    </v-row>
                    <v-row dense v-if="checkCurrentUser">
                      <v-col cols="5" md="6" sm="6" class="font-weight-bold">
                        EK netto
                      </v-col>

                      <v-col cols="6">
                        <span v-if="dataset.EK_netto_spezial">
                          {{ String(parseFloat(dataset.EK_netto_spezial.replace(",", ".").replace(" ", "")).toFixed(2)).replace(".", ",") + " €" }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row dense v-if="checkCurrentUser">
                      <v-col cols="5" md="6" sm="6" class="font-weight-bold">
                        EK brutto
                      </v-col>
                      <v-col cols="6">
                        <span v-if="dataset.EK_brutto">
                          {{ String(parseFloat(dataset.EK_brutto.replace(",", ".").replace(" ", "")).toFixed(2)).replace(".", ",") + " €" }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" xl="6" lg="6" md="6">
                    <v-row dense v-if="checkCurrentUser">
                      <v-col cols="5" md="6" sm="6" class="font-weight-bold">
                        VK netto
                      </v-col>
                      <v-col cols="6">
                        <span v-if="dataset.VK_netto">{{
                          String(parseFloat(dataset.VK_netto.replace(",", ".").replace(" ", "")).toFixed(2)).replace(".", ",") + " €"
                        }}</span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="5" md="6" sm="6" class="font-weight-bold">
                        VK brutto
                      </v-col>

                      <v-col cols="6">
                        <span v-if="dataset.VK_brutto">
                          {{ String(parseFloat(dataset.VK_brutto.replace(",", ".").replace(" ", "")).toFixed(2)).replace(".", ",") + " €" }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="5" md="6" sm="6" class="font-weight-bold">
                        Leihpreis
                      </v-col>
                      <v-col cols="6">
                        <span v-if="dataset.Leihpreis">
                          {{ String(parseFloat(dataset.Leihpreis.replace(",", ".").replace(" ", "")).toFixed(2)).replace(".", ",") + " €" }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider inset class="my-3"></v-divider>
                <v-row dense align="center">
                  <v-col cols="12" xl="6" lg="6" md="6">
                    <v-row dense>
                      <v-col cols="5" md="6" sm="6" class="font-weight-bold">
                        Kalkulation
                      </v-col>
                      <v-col cols="6">
                        <span v-if="dataset.Kalkulation">
                          {{ String(parseFloat(dataset.Kalkulation.replace(",", ".").replace(" ", "") * 100).toFixed(0)).replace(".", ",") + "%" }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" xl="6" lg="6" md="6">
                    <v-row dense>
                      <v-col cols="5" md="6" sm="6" class="font-weight-bold">
                        Mehrwertsteuer
                      </v-col>
                      <v-col cols="6">
                        <span v-if="dataset.MwSt">
                          {{ dataset.MwSt }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-card>
        </v-row>
        <!--Artikelhistorie-->
        <v-row dense class="align-center justify-center">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-title class="px-2">
                <v-row dense>
                  <v-col cols="12">
                    <v-toolbar-title>
                      Vorgänge
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider inset></v-divider>
              <v-card-text class="px-2 subtitle-2">
                <v-row dense>
                  <v-col cols="12">
                    <v-data-table
                      height="calc(48px + (48px * 5))"
                      fixed-header
                      hide-default-footer
                      :items="datasetEvents"
                      item-key="Artikelvorgang_ID"
                      :items-per-page="5"
                      :page.sync="page"
                      @page-count="pageCount = $event"
                      :headers="datasetEventsHeaders"
                      @dblclick:row="showDatasetEvent"
                      mobile-breakpoint="300"
                      style="width: 100%;"
                    >
                      <template #[`item.Vorgang`]="{ item }">
                        <span class="font-weight-bold"> {{ item.Vorgang }}</span>
                      </template>
                      <template #[`item.Datum`]="{ item }">
                        <span>{{
                          new Date(item.Datum).toLocaleDateString("de-DE", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                          }) + " Uhr"
                        }}</span>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider inset></v-divider>
              <v-card-actions>
                <v-row dense align="center">
                  <v-col cols="12">
                    <v-pagination color="grey darken-2" v-model="page" :length="pageCount"></v-pagination>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-card>
        </v-row>
      </v-col>
    </v-container>

    <OrderArticle ref="OrderArticle" :dataset="dataset" @confirmOrder="saveOrder" @abortOrder="closeOrderArticle" />
  </v-layout>
</template>

<script>
import OrderArticle from "./dialogs/OrderArticle.vue";

export default {
  components: {
    OrderArticle,
  },

  created() {
    this.initialize();
  },

  mounted() {
    this.initialize();
  },

  data: () => ({
    dataset: {},
    hiddenColumns: ["Artikelvorgang_ID", "Bestellung_ID", "Termin_ID", "Kunden_ID"],
    datasetEventsHeaders: [],
    datasetEvents: [],
    datasetPreorders: [],
    page: 1,
    pageCount: 0,
    OrderArticle: false,
  }),

  computed: {
    checkCurrentUser() {
      const user = localStorage.getItem("user");

      if (user === "Aline" || user === "Admin") {
        return true;
      } else return false;
    },

    linkEditDataset() {
      if (this.$route.params.id > 0) {
        return `/wws/database/${this.$route.meta.request}/dataset/edit/${this.$route.params.id}`;
      }
    },
    linkShowManufacturer() {
      if (this.dataset.Hersteller) {
        return `/wws/database/manufacturers/dataset/show/${this.dataset.Hersteller_ID}`;
      }
    },
  },

  methods: {
    async initialize() {
      const response = await fetch(`/api/database/${this.$route.meta.request}/dataset/show/${this.$route.params.id}`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      const alignmentHeaders = ["start", "end", "center"];
      const dividerHeaders = [true, true, false];

      this.datasetEventsHeaders = Object.keys(this.datasetEvents[0])
        .filter((it) => this.hiddenColumns.indexOf(it) === -1)
        .map((it, i) => {
          return {
            text: it,
            value: it,
            sortable: false,
            filterable: false,
            align: alignmentHeaders[i],
            divider: dividerHeaders[i],
            class: "text--primary text-uppercase",
          };
        });
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month] = date.split("-");
      return `${month} / ${year}`;
    },

    showDatasetEvent(event, { item }) {
      if (item.Bestellung_ID) {
        this.$router.push({
          path: `/wws/orders/ordered/dataset/show/${item.Bestellung_ID}`,
        });
      } else if (item.Termin_ID) {
        this.$router.push({
          path: `/wws/appointments/table/dataset/show/${item.Termin_ID}`,
        });
      }
    },

    openOrderArticle() {
      this.OrderArticle = true;
      this.$refs.OrderArticle.open();
    },

    closeOrderArticle() {
      this.OrderArticle = false;
    },

    async saveOrder(amount) {
      const datasetToOrder = {};

      if (Object.keys(datasetToOrder).length === 0) {
        Object.assign(datasetToOrder, {
          Artikel_ID: this.dataset.Artikel_ID,
          Bestellstatus_ID: 3,
          manuell_vorgemerkt: 1,
          zu_bestellende_Menge: Number(amount),
          verpflichtend: 0,
        });
      }

      await fetch(`/api/database/${this.$route.meta.request}/order/insert`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetToOrder),
      });

      await fetch(`/api/database/${this.$route.meta.request}/statusorderproposal`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetToOrder),
      });

      this.$root.actionSnackbar.show({
        dataset: "Artikel",
        action: "für Bestellung vorgemerkt",
      });

      this.initialize();
      setTimeout(() => {
        this.$root.mainmenu.reinitialize();
      }, 500);
    },
  },
};
</script>
